<template>
    <div class="paper-detail" v-if="Object.keys(questionList).length > 0">
        <div class="scroll-content">
            <el-scrollbar style="height: 100%">
                <div class="head">
                    <div class="title">{{ paperData.paper_name }}</div>
                    <div class="total-score">试卷总分(100分)</div>
                    <div class="back-button">
                        <el-button class="btn-blue" type="primary" @click="back">返回</el-button>
                    </div>
                </div>
                <PaperDetailList :paperDetail="questionList"></PaperDetailList>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
import PaperDetailList from "components/admin/paperManage/PaperDetailList";
import { theoryGetArtificialPaperDetail } from "@/utils/apis";
export default {
    name: "PaperDetail",
    data() {
        return {
            paperId: "",
            paperData: {},
        };
    },
    components: {
        PaperDetailList,
    },
    computed: {
        questionList() {
            if (Object.keys(this.paperData).length <= 0) return {};
            let typeText = ["single_data", "multiple_data", "judge_data", "fillBlank_data", "askAnswer_data"];
            let obj = {};
            this.paperData.topic_list.forEach((item) => {
                let typeKey = typeText[item.type - 1];
                if (!obj[typeKey]) {
                    obj[typeKey] = {};
                    obj[typeKey].list = [];
                    obj[typeKey].sum = 0;
                }
                obj[typeKey].list.push(item);
                obj[typeKey].sum++;
            });
            for (const key in obj) {
                obj[key].list.forEach((item) => {
                    if (!item.hasOwnProperty("student_answer")) {
                        this.$set(item, "student_answer", []);
                        if (key === "single_data" || key === "judge_data" || key === "askAnswer_data") {
                            item.student_answer = [""];
                        }
                        if (key === "multiple_data") {
                            item.student_answer = [];
                        }
                        if (key === "fillBlank_data") {
                            for (let i = 0; i < item.options.length; i++) {
                                let tmp = {
                                    name: "",
                                };
                                item.student_answer.push(tmp);
                            }
                        }
                    } else {
                        if (key === "fillBlank_data") {
                            let list = [];
                            for (let i = 0; i < item.student_answer.length; i++) {
                                let tmp = {
                                    name: item.student_answer[i],
                                };
                                list.push(tmp);
                            }
                            item.student_answer = list;
                        }
                    }
                });
            }
            return obj;
        },
    },
    methods: {
        async getData() {
            let res = await theoryGetArtificialPaperDetail({ paper_id: this.paperId });
            if (res.code == 200) {
                res.data.topic_list.forEach((item) => {
                    let { options, answer } = item;
                    item.options = options ? JSON.parse(options) : undefined;
                    item.answer = answer ? JSON.parse(answer) : undefined;
                });
                this.paperData = res.data;
            } else {
                this.$message.error(res.msg);
            }
        },
        back() {
            this.$router.back();
        },
    },
    mounted() {
        let id = this.$route.query.id;
        if (id) {
            this.paperId = id;
            this.getData();
        }
    },
};
</script>

<style scoped lang="scss">
.paper-detail {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px 0;
    color: #333;
    .head {
        position: relative;
        font-size: 22px;
        text-align: center;
        .total-score {
            margin-top: 5px;
            font-size: 16px;
            color: #666;
        }
        .back-button {
            position: absolute;
            top: 50%;
            left: 30px;
            transform: translateY(-50%);
        }
    }
    .scroll-content {
        flex: 1;
        overflow: hidden;
    }
}
</style>
